export enum Locales {
  EN = "en",
  ES = "es",
  RU = "ru"
}

export const LOCALES = [
  { value: Locales.EN, caption: "English" },
  { value: Locales.ES, caption: "Spanish" },
  { value: Locales.RU, caption: "Russian" }
]
