import { Locales } from "./locales"

import en from "./en.json"
import es from "./es.json"
import ru from "./ru.json"

export const messages: any = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.RU]: ru
}

export const defaultLocale = Locales.EN
